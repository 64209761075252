@mixin border($padding: 6px) {
  padding: $padding;
  background-color: #FFF;
  box-shadow: 0 0 0 1px #CCC;
}

@mixin frame--square($height: 150px, $width: 150px, $img: true) {
  @include border;

  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  height: $height;
  width: $width;
  position: relative;

  @if $img {
    img {
      transform: translate(-50%, -50%) scale(1.55);
      position: absolute;
      left: 50%;
      top: 50%;
      height: 100%;
      width: auto;
      margin-bottom: 1rem;
    }
  }
}

@mixin shadowed {
  box-shadow:
    0 0 0 1px #CCC,
    0 11px 0 -5px rgba(0, 0, 0, 0.2);

  a:hover & {
    transform: translateY(-3px);
    box-shadow:
      0 0 0 1px #CCC,
      0 14px 0 -5px rgba(0, 0, 0, 0.2);
  }
}
