// this mixin is designed to be applied to a css `a` selector or a class applied to an html anchor tag

@mixin link ($link, $hover: false, $active: false, $visit: false, $border: false) {
  color: $link;

  // If a hover value isn't specified then default to darken 15%
  @if $hover == false {
    $hover: darken($link, 15%);
  }

  &:hover {
    color: $hover;
  }
  // If an active value isn't specified use the hover value
  @if $active {
    &:active {
      color: $active;
    }
  }

  @else {
    &:active {
      color: $hover;
    }
  }
  // If a visit value is specified set one
  @if $visit {
    &:visited {
      color: $visit;
    }
  }
  // If a border color value is specified set one
  @if $border {
    border-bottom: 1px solid $border;
  }
}

// EXAMPLE: Using one parameter

//   a {
//     @include link($sbf-blue)
//   }

// EXAMPLE: Using two parameters

//   a {
//     @include link($sbf-blue, $sbf-light-blue)
//   }
