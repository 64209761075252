@import "~sbf-design-system/scss/base";
// Mypage Header Styles

// Hiding Donate and Search from header
.old-header__right-block {
  top: -28px;

  @media (min-width: $medium) {
    top: -6px;
  }

  .button {
    display: none;
  }

  .old-header__nav {
    padding: 0;
    top: 0;
  }
}

@media (min-width: $medium) {
  .old-header__right-block .old-header__search {
    visibility: hidden;
    width: 0;
    margin: 0;
    overflow: hidden;
  }
}

.mypage-header {
  padding: 200px 0 40px;
  margin: 0 -8px;
  color: white;
  border-bottom: 1px solid #DBDBDB;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $sbf-black;

  .button.button {
    color: white;
    font-weight: 600;
    margin-bottom: 0;
  }

  a {
    @include link($sbf-blue);

    text-decoration: underline;
    font-weight: 600;
    border: none;

    @media (min-width: $medium) {
      @include link($sbf-dark-yellow);
    }
  }

  .donate-button {
    @include sbf-button($btnType: "sbf-dark-green");
  }

  .join-button {
    @include sbf-button($btnType: "sbf-blue");
  }

  &::before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.05) 1%, rgba(0, 0, 0, 0.6) 100%);
  }

  &::after {
    content: '';
    z-index: 1;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%, $sbf-black 150%);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  @media (max-width: $medium - 1) {
    background-size: auto 275px;
    background-position: center top;
    background-repeat: no-repeat;
    background-color: $sbf-white;
    margin: 0 -7px;
  }
}

.mypage-header__flag {
  padding: 0 0 16px;
  z-index: 5;
  display: block;

  @media (min-width: $medium) {
    padding: 32px 16px;
  }

  h1 {
    @include sbf-heading('headline-24-sans');

    word-wrap: break-word;
    width: 100%;
  }

  h2 {
    @include sbf-body('default-sans');

    font-weight: 500;
    margin-bottom: 8px;
  }

  p {
    @include sbf-body('small-sans');

    margin: 0;
    font-weight: 500;
  }

  .small {
    @include sbf-body('mini-sans');

    margin-top: 8px;
    padding-right: 5px;
    display: inline-block;
    position: relative;
  }

  .flag__body {
    @media (max-width: $medium - 1) {
      display: block;
      text-align: center;
      padding-left: 0;
    }
  }
}

.flag__image {
  height: 200px;
  width: 200px;

  img {
    @include border($padding: 3px);

    height: 200px;
    width: 200px;

    @media (max-width: $medium - 1) {
      box-shadow: 0 12px 0 -6px #858585;
      margin: -75px auto 50px;
    }
  }
}

.fancy-buttons {
  display: none;
  position: absolute;
  bottom: 34px;
  right: 24px;

  @media (min-width: $x-large) {
    display: block;
    right: 23px;
  }

  .button {
    min-width: 150px;
    text-align: center;
    margin-left: 16px;
  }

  &.is-pinned {
    .button.button { // extra specificity needed here until we update event mypage
      line-height: 1.15em;
    }
  }

  .link-give-by {
    display: block;
    text-align: center;
    margin: 4px 0 0 16px;
    z-index: 2;
  }
}

.participant-id,
.event-id,
.team-id,
.fundraiser-id,
.kid-id {
  color: #FFF; // fallback for browsers having issues with rgba
  color: rgba(255, 255, 255, 0.8);

  @include sbf-heading('headline-12-sans');

  font-weight: 600;
  z-index: 3;
  min-width: 5px;
  padding: 0 22px;

  &.desktop-only {
    position: relative;
    text-align: right;
    top: -157px;
  }

  &.mobile-only {
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: $medium - 1) {
  .mypage-header {
    color: $sbf-black;
    padding: 0;

    &::after,
    &::before {
      height: 275px;
      z-index: 1;
    }
  }

  .mypage-header__flag {
    top: 0;
  }

  .flag__image,
  .flag__body {
    display: block;
    text-align: center;
  }

  .flag__body {
    padding-left: 0;
  }

  .flag__image {
    margin: -115px auto 50px;
  }
}

@media (min-width: $medium) {
  .mypage__header {
    margin: 0 -8px;
    border-bottom: none;
  }
}
