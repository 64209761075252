@import "~sbf-design-system/scss/base";
// Mypage Sidebar

$gray: #CED1D6;

// TOOLS & MIXINS
.ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
// ---

.mypage__sidebar {
  padding: 0 24px;
  min-width: 300px;
  width: 100%;
  text-align: center;

  h2 {
    margin-bottom: 10px;
  }

  h3 {
    @include sbf-heading('headline-24-sans');
  }

  p {
    @include sbf-body('mini-sans');

    @media (max-width: $medium - 1) {
      max-width: 350px;
      margin: 0 auto 25px;
    }
  }

  .condensed-header {
    margin-bottom: 0.5em;

    @media (min-width: $large) {
      margin-bottom: 20px;
    }
  }

  &--bottom {
    order: 2;
  }

  a {
    @include link($sbf-blue);

    text-decoration: underline;
    font-weight: 600;
    border: none;
  }

  .button {
    border-bottom: none !important;
  }

  .research-link {
    padding: 2px 0 0;
  }

  @media (min-width: $medium) {
    padding-left: 6px;
    text-align: left;
    width: 38%;
    flex: 1 1 auto;
  }

  @media (min-width: $large) {
    padding: 0 32px 0 8px;
  }

  @media (min-width: 1100px) {
    padding: 0 32px 0 15px;
  }

  .event-id {
    display: block;

    @media (min-width: $medium) {
      display: none;
    }
  }

  .mypage__fundraising__give-by-link {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 12px auto 0 auto;

    @media (min-width: $medium) {
      margin: 0 auto;
    }

    p,
    ul {
      font-family: $sourceSans;
    }

    p {
      @include sbf-body('small-sans');

      margin: 0;
    }

    ul {
      display: flex;
      gap: 8px;
      margin: 0 0 0 4px;
      list-style-type: none;
    }
  }
}

.mobile-stats {
  display: none;
}

// Top Teams, Top Participants, and Recent Donor Styles || Sidebar
.fundraising__raised.fundraising__raised {
  color: $sbf-dark-green;
  margin-bottom: 5px;
  font-size: 28px; // There is not a TK mixin for this
}

.fundraising__goal {
  @include sbf-heading('headline-12-sans');
}

.fundraising__controls {
  align-self: flex-start;
  width: 100%;
  line-height: 1.6;
  margin-top: -0.3em;
  text-align: center;

  @media (max-width: 759px) {
    margin: 10px 0 -11px 0;
  }

  p {
    @include sbf-body('small-sans');

    margin: 0 auto;
  }

  .donate-by-phone-small {
    @media (max-width: $x-large - 1) {
      display: none;
    }
  }

  .donate-by-phone-large {
    @media (min-width: $x-large) {
      display: none;
    }
  }

  .donation-form-download {
    @include sbf-body('small-sans');

    @media (max-width: $x-large - 1) {
      display: none;
    }
  }

  @media (min-width: $x-large) {
    width: auto;
    text-align: left;
    margin-left: 16px;
    padding-left: 16px;
    border-left: 1px solid #CCC;
  }
}

.fundraising__stats {
  margin-top: 40px;
  text-align: left;

  h3 {
    display: inline-block;
  }

  ol {
    counter-reset: aCounter;
    list-style: none;
  }

  li {
    @include sbf-body('mini-sans');

    counter-increment: aCounter;
    padding-left: 16px;
    z-index: 1;
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 8px;

    &::before {
      content: counter(aCounter) '. ';
      color: #333;
      position: absolute;
      left: 0;
    }

    .donor__name {
      word-break: break-word;
    }

    span.dash {
      flex: 1;
      border-bottom: 1px solid $gray;
      margin: 0 5px 5px 5px;
    }

    span,
    a {
      display: inline-block;
      padding: 0;
    }

    a {
      max-width: 55%;

      @extend .ellipses;
    }

    span.totals__name {
      max-width: 55%;
      word-break: break-word;

      @extend .ellipses;
    }
  }

  .totals__amount,
  .sidebar__date {
    float: right;
    color: #333;
  }
}

.fundraising__stats-view-all {
  @include sbf-body('mini-sans');

  float: right;
  top: 4px;
}

.stats--green {
  a {
    @include link($sbf-dark-green);

    text-decoration: underline;
    font-weight: 600;
    border: none;
  }
}
// Anniversary Callout
.mobile-callout {
  display: block;

  @media (min-width: $medium) {
    display: none;
  }
}

.desktop-callout {
  @media (min-width: $medium) {
    display: block;
  }

  display: none;
}

.anniversary-callout {
  display: block;
  border: 1px solid #DBDBDB;
  background-color: $sbf-white;
  padding: 0 20px;
  margin: 25px -8px 0;
  text-align: center;

  .logo-anniversary {
    text-align: center;
    padding-top: 24px;
  }

  svg {
    max-height: 100px;
  }

  @media (min-width: $medium) {
    padding: 0 49px;
    margin: 25px 0 0 0;
  }
}

// National Partner
.national-partner {
  margin-top: 40px;

  .condensed-header {
    margin-bottom: 10px;
  }

  .partner-logo {
    text-align: center;
  }

  svg {
    max-height: 80px;
  }
}
