@import "~sbf-design-system/scss/base";
// Honored Kids

$custom-kid-bp: 989px;

// TOOLS & MIXINS
.ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
// ---

.mypage__kids {
  padding-top: 24px;

  .mypage__kids__content {
    h3,
    p {
      padding: 0 12px;
    }

    h3 {
      @include sbf-heading('headline-24-sans');

      white-space: nowrap;
      text-align: center;

      @media (min-width: $medium) {
        text-align: left;
      }
    }

    p {
      @include sbf-body('default-serif');
    }
  }

  @media (min-width: $medium) {
    margin: 0 -12px;
  }

  .centered {
    text-align: center;

    @media (min-width: $medium) {
      text-align: left;
    }
  }
}

.kid_anchor {
  top: -75px;
}

.kid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0 12px;

  @media (min-width: $medium) {
    margin-bottom: 0;
  }

  @media (min-width: $custom-kid-bp) {
    padding: 0;
  }
}

.kid-info {
  background: linear-gradient(to bottom, $sbf-black 87px, $sbf-white 87px);
  flex: 1 0 auto;
  width: 100%;
  margin: 12px 0;
  border: 1px solid #FFF;
  text-align: left;

  @media (min-width: 630px) and (max-width: $medium - 1) { // side by side for tablet view
    flex: 1 0 46%;
    max-width: 46%;
    margin: 14px 2%;
    border: 1px solid #DBDBDB;
  }

  @media (min-width: $custom-kid-bp) { // side by side on larger desktop views
    flex: 1 0 46%;
    max-width: 46%;
    margin: 14px 2%;
    border: 1px solid #DBDBDB;
  }
}

.kid-info-inner {
  display: flex;
  flex-flow: row nowrap;

  .photo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 87px;
    height: 87px;
    background-color: #FFF;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}

.kid-info-content {
  flex: 1 0 50%;
  padding: 16px;
  min-width: 0;

  h3 {
    color: white;

    @include sbf-body('default-sans');

    font-weight: bold;
    margin-bottom: 48px;
    padding-top: 16px;

    @extend .ellipses;
  }

  p {
    @include sbf-body('mini-sans');

    margin: 2px 0 0 -87px;
    color: #717171;

    @extend .ellipses;

    padding: 0;
  }

  .icon {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    margin: 4px 4px 4px 0;
  }
}

// Hack to fix linear-gradient height issue with firefox.
// IE also has this issue but we arn't going to worry about it at this time because there isn't a good way to target IE.
@-moz-document url-prefix() {
  .kid-info { background: linear-gradient(to bottom, $sbf-black 87px, $sbf-white 87px); }
}

@mixin small-kids {
  background: $sbf-black;

  .kid-info-inner {
    .photo-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 110px;
      background-color: #FFF;

      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }

    .kid-info-content {
      padding: 16px 16px 0 16px;

      h3 {
        @include sbf-body('mini-sans');

        padding-top: 0;
        margin-bottom: 0;
      }

      p {
        @include sbf-body('mini-sans');

        color: white;
        margin: 0;

        &::before {
          content: '●';
        }
      }
      .icon { display: none; }
      .kid__location { display: none; }
    }
  }
}

.more-than-two .kid-info {
  @include small-kids;
}

@media (max-width: $custom-kid-bp - 1) {
  .kid-info {
    @include small-kids;
  }
}
