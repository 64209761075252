@import "~sbf-design-system/scss/base";

// *------------------------------------*\
//   # Fundraising Block Styles
//\*------------------------------------*/

.fundraising__goal {
  margin-bottom: 0;
}

.dashboard__fundraising {
  width: 100%;

  progress[value] {
    background-color: #E4EAF0;
    height: 12px;
    margin-bottom: 24px;
    width: 100%;
    border: none;

    &::-webkit-progress-bar {
      background-color: #E4EAF0;
    }

    &::-webkit-progress-value {
      background: $sbf-dark-green;
    }
  }

  progress::-moz-progress-bar {
    background: $sbf-dark-green;
  }

  progress { //IE fix
    color: $sbf-dark-green;
  }
}

.fundraising__block {
  .icon {
    display: none;

    @media (min-width: $large) {
      display: block;
    }
  }

  .body-only {
    display: block;
  }

  .mobile-only {
    display: none;

    @media (max-width: $x-large) {
      display: inline-block;
      min-width: 0;
    }
  }

  .button {
    flex: 1 1 0;
    margin-left: 0;
  }

  .join-button.mobile-only {
    @media (min-width: $x-large) {
      display: none;
      min-width: 0;
    }
  }

  .donate-button {
    min-width: 100px;
    max-width: 46%; //IE Fix

    @media (min-width: $x-large) {
      margin-right: 0;
      margin-left: 0;
      padding: 8px 16px;
      min-width: 90px;
      flex: 1;
    }

    &.full-width {
      max-width: 100%;
    }
  }

  .phone-number {
    white-space: nowrap;
  }
}

.mypage--editable .editable_fundraising_trigger.desktop-only {
  display: none;

  @media (min-width: $large) {
    display: block;
  }
}
