
.fundraising__stats.donors,
.fundraising__stats.teams,
.fundraising__stats.participants {
  // websocket updates animations
  li {
    transform-origin: center 35%;
    transition: top 1s ease, opacity 1s ease;

    &.new {
      animation: new-donation 1.5s ease-in-out 1 both;
    }

    &.fade-out {
      opacity: 0;
    }

    &.update {
      .totals__amount {
        animation: update-total 1s linear 1 both;
      }
    }
  }

  .empty-message {
    display: none;
  }

  &.empty {
    ol {
      display: none;
    }

    .empty-message {
      display: block;
    }
  }
}

.fundraising__stats.teams,
.fundraising__stats.participants {
  height: 100px;

  li {
    &[data-position] {
      position: absolute;
      left: 0;
      right: 0;
    }

    @for $i from 1 through 8 {
      &[data-position='#{$i}'] {
        top: 27px * ($i - 1);
        order: $i;

        &::before {
          content: '#{$i}. ';
        }
      }
    }
  }
}

.fundraising__stats.donors.empty {
  display: none;
}

@keyframes new-donation {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }

  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }

  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }

  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

@keyframes update-total {
  0% {
    transform: scale(1) rotateX(0);
  }

  50% {
    transform: scale(0.8) rotateX(180deg);
  }

  100% {
    transform: scale(1) rotateX(360deg);
  }
}
