// Toolkit Button Mixin
@mixin sbf-button($btnType: "default", $btnSize: "default", $background-color: $sbf-white, $border-color: $sbf-black, $color: $sbf-black, $hover-background: $sbf-black, $hover-border: $sbf-black, $hover-color: $sbf-white) {

  // Check for null btnType
  // $btnType: "null" is used when declaring a button size only.
  @if $btnType != "null" {

    // Check if btnType is valid
    @if map-has-key($btnTypes, $btnType) {

      // Setup default values for each btnType
      $primary-color: map-get($btnTypes, $btnType);
      $background-color: $primary-color;
      $border-color: $primary-color;
      $color: #FFF;
      $hover-background: darken($primary-color, 10%);
      $hover-border: darken($primary-color, 10%);

      // Exception to the rule:
      // If btnType is default, override a few varibles.
      @if $btnType == "default" {
        $primary-color: $sbf-black;
        $background-color: $sbf-white;
        $color: $sbf-black;
      }
    }
    // If the btnType provided isn't valid show a warning in the logs.
    @else {
      @if $btnType != "default" {
        @warn "Unfortunately, no value could be retrieved from `#{$btnType}`. "
          + "Please make sure it is defined in `$btnTypes` map.";
      }
    }

    // Don't output these properties if btnType: "null"
    background-color: $background-color;
    border-color: $border-color;
    border-radius: 3px;
    color: $color;
    font-family: $sansSerifFamily;
    font-weight: bold;

    // Static Button Properties (grouped to aid in compression)
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 10px;
    text-decoration: none;
    transition:
      background 50ms linear,
      border-color 50ms linear,
      color 50ms linear,
      opacity 50ms linear;
    white-space: nowrap;
    z-index: 2;
    letter-spacing: -0.005em;

    &:hover,
    &:focus {
      color: $hover-color;
      border-bottom-width: 2px;
      border-color: $hover-border;
      background-color: $hover-background;
    }
  }

  // Define button sizes here...
  // Be sure to also define them in the buttons components for legacy support.
  $padding: 15px 16px;
  $font-size: 18px;
  $line-height: 1.15em;
  $min-width: 200px;

  @if $btnSize == "small" {
    $padding: 10px 16px 12px 16px;
    $font-size: 16px;
    $min-width: initial;
  }

  padding: $padding;
  font-size: $font-size;
  min-width: $min-width;
  line-height: $line-height;
}

// Special mixin for the back button
@mixin sbf-back-button {
  border-radius: 3px;
  color: $sbf-blue;
  font-family: $sansSerifFamily;
  font-weight: bold;
  line-height: $baseLineHeight;
  font-size: 18px;

  // Static Button Properties (grouped to aid in compression)
  background-color: initial;
  border: 0;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 10px;
  text-decoration: none;
  transition:
    background 50ms linear,
    border-color 50ms linear,
    color 50ms linear,
    opacity 50ms linear;
  white-space: nowrap;
  z-index: 2;
  letter-spacing: -0.005em;
  padding: 15px 16px;
  min-width: initial;

  &:hover,
  &:focus {
    background-color: initial;
    color: $sbf-black;
  }
}

@mixin sbf-edit-ui ($size: 'small') {
  height: 26px;
  width: 26px;
  font-size: 18px;
  text-align: center;
  vertical-align: middle;
  font-weight: 400;
  display: block;
  color: $sbf-white;
  border-radius: 0;

  @if $size =='large' {
    height: 36px;
    width: 36px;
  }

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;

    @if $size =='large' {
      height: 24px;
      width: 24px;
      margin-top: 4px;
    }
  }

  &:focus {
    outline: none;
  }

  &.top-right {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  &.tk-edit-button {
    // green pencil button
    background-color: $sbf-edit-green;

    &:hover,
    &:focus {
      background-color: $sbf-dark-green;
    }
  }

  &.tk-delete-button {
    // red X button
    background-color: $sbf-red;
    right: 26px;

    &:hover,
    &:focus {
      background-color: darken($sbf-red, 10%);
    }
  }

  &.tk-remove-button {
    // red trashcan button
    background-color: $sbf-red;
    right: 36px;

    &:hover,
    &:focus {
      background-color: darken($sbf-red, 10%);
    }

    .icon {
      height: 20px;
      width: 20px;
    }
  }

  &.tk-sort-button {
    // grey move button
    background-color: #777;
    cursor: move;
    cursor: -webkit-grabbing;

    &:hover,
    &:focus {
      background-color: #656565;
    }
  }

  &.tk-add-button {
    // round "+" button
    @include sbf-edit-add();
  }
}

@mixin sbf-edit-outlined () {
  outline: 1px dashed #CCC;

  .blur-on-enter {
    padding-right: 1px;
    outline: none;
  }
}

@mixin sbf-edit-add () {
  background-color: $sbf-edit-green;
  color: #FFF;
  border-radius: 50%;
  padding: 4px;

  &:hover,
  &:focus {
    background-color: $sbf-dark-green;
  }
}
