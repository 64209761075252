@import "~sbf-design-system/scss/base";
// The base stylesheet for all mypages

@import 'sticky-header';
$gray: #CED1D6;

// *------------------------------------*\
//   #SETTINGS
//\*------------------------------------*/

[role="main"] {
  margin-top: -25px;

  @media (min-width: $medium) {
    margin-top: -48px;
  }
}

// Hide editable UI from non-editable page state
.tk-editable-ui,
.tk-editable-ui--large {
  display: none;
}

// *------------------------------------*\
//   #COMPONENTS
//\*------------------------------------*/

/* PAGE SECTIONS */

// Mypage Header
@import 'sections/base-header';

// Mypage Body Content
.mypage__content {
  padding: 36px 0;
  color: $sbf-black;

  @media (min-width: $medium) {
    padding: 70px 0;

    > div {
      display: flex;
      flex-flow: row;
    }
  }
}

// Mypage Sidebar
@import 'sections/base-sidebar';

// Mypage Main Column Content
.mypage__main-column {
  padding: 32px 16px 0;
  min-width: 300px;
  width: 100%;
  overflow-x: hidden;

  h2 {
    margin-bottom: 8px;
    letter-spacing: -1px;
    line-height: 32px;
    font-size: 28px; // There is not a TK mixin for this
  }

  @media (min-width: $small) {
    min-width: 350px;
  }

  @media (min-width: $medium) {
    width: 62%;
    flex: 1 1 auto;
    padding: 0 16px 0 24px;
    box-shadow: -1px 0 0 0 #CCC;
  }

  @media (min-width: $large) {
    padding: 0 15px 0 32px;
  }

  @media (min-width: 1090px) {
    padding: 0 24px 0 32px;
  }

  .mypage__message {
    @include sbf-body('small-serif');

    @media (min-width: $large) {
      @include sbf-body('default-serif');
    }

    a {
      @include link($sbf-blue);

      text-decoration: underline;
      font-weight: 600;
      border: none;
    }
  }
}

// Mypage Gallery Section
@import 'sections/base-gallery';
@import 'sections/base-photo-lightbox';

// Honored Kids
@import 'sections/base-kids';

// Internal List Page || Donors
@import 'sections/base-list-page';

.mypage-video {
  margin: 0;
  max-width: 100%;
}

// Only Display Youtube Chat On Desktop
[data-chat-enabled="true"] {
  .youtube-chat {
    display: none;
    min-height: 360px;

    @media (min-width: $large) {
      display: block;
    }
  }
}

[data-chat-enabled="false"] {
  .youtube-chat {
    display: none;
  }
}

// iPhone 4 Fixes
@media only screen and (device-aspect-ratio: 2/3) {
  .fundraising__list .list-header .back-to-mypage {
    margin: 0 auto 2em;
    width: 200px;

    .icon,
    .link-back {
      float: left;
      margin-top: 0;
    }
  }
}
