@import "~sbf-design-system/scss/variables";

.mypage--editable {
  .fancy-buttons.is-pinned {
    .link-give-by {
      display: none;
    }
  }
}

.fancy-buttons {
  .sbf-mark {
    display: none;
    height: 100%;
    padding: 0;

    .icon {
      height: 40px;
      width: 40px;
      vertical-align: middle;
    }
  }

  .tagline {
    display: none;
    margin-left: 16px;
    font-family: $serifFamily;
    font-weight: 500;
    font-style: italic;
    font-size: 18px;
    letter-spacing: 0.025em;
  }
}

.fancy-buttons.is-pinned {
  transition: background 350ms linear;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: auto;
  z-index: 111151;
  height: 82px;
  line-height: 82px;
  background: rgba(0, 0, 0, 0.92);
  color: white;
  text-align: right;

  .link-give-by {
    display: inline;
  }

  .sbf-mark,
  .tagline {
    display: block;
    float: left;
    color: white;
  }
}

.no-animate {
  transition: none !important;
}

.fancy-buttons.is-pinned .fancy-buttons__inner {
  max-width: 1080px;
  min-width: 280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}
