@mixin sbf-inline-input () {
  font-family: $sansSerifFamily;
  font-size: 1em;
  font-weight: normal;
  line-height: normal;
  display: inline-block;
  position: relative;
  margin: 0 12px 0 0;
  padding: 12px 0;
  cursor: text;

  input,
  textarea,
  select {
    width: 100%;
    padding: 1em 1em;
    font-size: 1em;
    font-family: $sansSerifFamily;
    color: $sbf-black;
    border-radius: 3px;
    background: #FFF;
    border: 1px solid $sbf-dark-grey;
    box-shadow: none;
    appearance: none;
    -webkit-appearance: none;

    &[type=number] {
      appearance: none;
      -moz-appearance: textfield;
    }

    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
      appearance: none;
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      outline: solid 2px $sbf-state-focus;
      outline-offset: 2px;
    }
  }

  select {
    background: url('#{$svg-icon-path}select-dropdown-arrow.svg') no-repeat right 1rem center;
    background-size: 0.6rem auto;
    background-color: #FFF;
    cursor: pointer;
  }

  input,
  select {
    // small hack to fix an issue where the input fields height
    // in Chrome and IE is 1px less then Firefox when at our base font size.
    min-height: 54px;
    height: 54px;
  }

  label {
    font-size: inherit;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 1;
    top: 1.75em;
    left: 1em;
    cursor: text;
    transition: transform 0.2s ease-out;
    transform: scale(0.8) translate(-1em, -3em);
    transform-origin: left;
    pointer-events: none;
  }

  &.empty label {
    transform: none;
    color: #6E6E6E;
  }

  &.currency-usd input {
    padding: 10px 63px 10px 30px;

    // apparently you have to list these all individually *facepalm*
    &::-webkit-input-placeholder {
      color: $sbf-blue;
    }

    &::-moz-placeholder {
      color: $sbf-blue;
    }

    &:-ms-input-placeholder {
      color: $sbf-blue;
    }

    &:-moz-placeholder {
      color: $sbf-blue;
    }
  }

  &.currency-usd label {
    padding-left: 0;
  }

  &.empty.currency-usd label {
    padding-left: 0.4em;
  }

  &.invalid {
    input,
    select {
      border: 1px solid $sbf-red;
    }

    &.empty {
      label {
        color: $sbf-red;
        padding-left: 24px;

        &::before {
          position: absolute;
          display: block;
          content: '!';
          left: 2px;
          background-color: $sbf-red;
          color: white;
          line-height: 15px;
          width: 15px;
          border-radius: 50%;
          font-weight: bold;
          top: 3px;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }

  .email-checker {
    position: absolute;
    left: 0;
    right: 0;
    font-size: 14px;
    z-index: 1002;
    background-color: $sbf-palette-white;
    margin-top: 4px;
    display: flex;
    white-space: nowrap;
    line-height: 1.45;
  }

  .email-checker__label {
    display: flex;
    overflow: hidden;
  }

  .email-checker__result {
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    direction: rtl;
    margin-left: 0.2em;
    padding: 0;
    font-weight: 600;
    text-decoration: underline;

    &:focus-visible {
      outline-offset: -2px;
    }
  }

  .email-checker__close {
    cursor: pointer;
    margin-left: 3px;
    height: 14px;
    display: inline-block;
    margin-top: 2px;
    padding: 0;
  }

  .icon {
    color: #6E6E6E;
  }

  ::-webkit-input-placeholder {
    color: #6E6E6E;
  }

  :-moz-placeholder {
    color: #6E6E6E;
  }

  ::-moz-placeholder {
    color: #6E6E6E;
  }

  :-ms-input-placeholder {
    color: #6E6E6E;
  }
}

@mixin sbf-donate-amount () {
  display: inline-block;

  &::before {
    content: '$';
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(0, -50%);
    font-weight: 600;
    font-size: 1.286em;
    color: #6E6E6E;
    z-index: 1;
  }

  &::after {
    content: 'USD';
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    font-weight: 700;
    font-size: 1.071em;
    color: #6E6E6E;
  }

  input {
    padding: 10px 63px 10px 30px;
  }
}

@mixin braintree-hosted-fields () {
  width: 100%;
  padding: 1em 1em;
  font-size: 1em;
  height: 54px;
  font-family: $sansSerifFamily;
  color: $sbf-black;
  border-radius: 3px;
  background: #FFF;
  border: 1px solid $sbf-dark-grey;
  appearance: none;
  -webkit-appearance: none;

  &.invalid {
    border: 1px solid $sbf-red;
  }
}

@mixin calendar-datepicker () {
  input {
    background: url('#{$svg-icon-path}calendar.svg') no-repeat;
    background-size: 25px;
    background-position: 95% center;
    padding: 16px 42px 16px 16px;
    cursor: pointer;
  }

  &.empty label {
    transform: scale(0.8) translate(-1em, -3em);
    color: $sbf-black;
  }
}
