@import "~sbf-design-system/scss/base";
@import 'base'; // Imports the base stylesheet
@import "../websockets/websockets";

// Participant Mypage Specific Styles go here
@import 'sections/participant-league'; // League Status Box and Headings

// Styles ported from src/forms.scss
.form-input--invalid.empty {
  label {
    color: $sbf-red;
    padding-left: 24px;

    &::before {
      position: absolute;
      display: block;
      content: '!';
      left: 2px;
      background-color: $sbf-red;
      color: white;
      line-height: 15px;
      width: 15px;
      border-radius: 50%;
      font-weight: bold;
      top: 3px;
      font-size: 12px;
      text-align: center;
    }
  }

  select {
    color: #222;
  }

  input {
    color: #444;
  }
}

[type="search"] {
  -webkit-appearance: none;
}

// Parsley form validation

input.parsley-success,
select.parsley-success,
textarea.parsley-success {
  color: $sbf-green;
  border: 1px solid #D6E9C6;
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error {
  color: $sbf-red;
  border: 1px solid #EED3D7;

  & + select + label,
  & + label {
    color: $sbf-red;
  }
}

.inline-input.parsley-success {
  input {
    border: 1px solid #D6E9C6;
  }
}

.inline-input.parsley-error {
  color: $sbf-red;

  input {
    border: 1px solid #EED3D7;
  }
}

.parsley-errors-list {
  margin: 0;
  padding: 1.2em;
  list-style-type: none;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.6;
  opacity: 0;
  height: 0;
  position: absolute;
  background: $sbf-red;
  color: white;
  font-family: $sansSerifFamily;
  width: 100%;
  transition:
    opacity 0.3s ease-in,
    height 0.3s ease-in;

  &::before {
    display: none;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    left: -20px;
    top: 16px;
    border-width: 20px 20px 20px 0;
    border-color: transparent $sbf-red;
    border-style: solid;
  }
}

.parsley-errors-list.filled {
  opacity: 1;
  height: auto;
  position: relative;
}

// *------------------------------------*\
//   #SETTINGS
//\*------------------------------------*/

.editable-mypage-ui {
  display: none; // don't display edit buttons unless the page is editable
}

.desktop-only {
  @media (max-width: $medium - 1) {
    display: none;
  }
}

.header-only,
.body-only {
  display: none;
}

.inner {
  margin: 0;
}

.button--green.fill {
  background: $sbf-dark-green;
}

// *------------------------------------*\
//   # Header Styles
//\*------------------------------------*/

.mypage-header {
  background-image: url('participant/header-bg.jpg');

  &::before {
    background: linear-gradient(to bottom, rgba(10, 10, 10, 0) 1%, rgba(10, 10, 10, 0) 42%, rgba(10, 10, 10, 1) 61%, rgba(10, 10, 10, 1) 100%);

    @media (min-width: 400px) {
      background: linear-gradient(to bottom, rgba(10, 10, 10, 0) 1%, rgba(10, 10, 10, 0) 42%, rgba(10, 10, 10, 1) 69%, rgba(10, 10, 10, 1) 100%);
    }
  }

  .money__dollar {
    padding-left: 10px;
    font-size: 12px;
    line-height: 2;
  }

  .fundraising__block .header-only {
    display: block;

    .icon {
      margin-left: 4px;
    }

    .blur-on-enter {
      width: inherit;
      display: inline;
    }

    span.tk-editable-ui.money__amount {
      vertical-align: baseline;
      font-size: 12px;
      line-height: 2;
      padding-top: 0;
    }
  }

  .fundraising__controls {
    color: $sbf-white;
    z-index: 3;

    .donate-by-phone-small {
      display: inline-block;
      margin-top: 15px;
      margin-bottom: 3px;
    }

    a {
      @include link($sbf-dark-yellow);

      margin-top: 5em;
      width: 10%;
    }
  }

  .mypage__fundraising__give-by-link {
    display: flex;
    justify-content: center;
    margin: 12px auto 0 auto;
    z-index: 2;

    @media (min-width: $medium) {
      margin: 0 auto;
    }

    p,
    ul {
      font-family: $sourceSans;
    }

    p {
      @include sbf-body('small-sans');

      margin: 0;
      color: $sbf-white;
    }

    a {
      @include link($sbf-dark-yellow);
    }

    ul {
      display: flex;
      gap: 8px;
      margin: 0 0 0 4px;
      list-style-type: none;
    }
  }

  .dashboard__fundraising {
    width: 100%;
    height: 1.1em;
    margin: 0 auto 24px;
    bottom: 28px;
    border-radius: 15px;
    background-color: #616161;
    overflow: hidden;
    z-index: 3;

    @media (min-width: $small) {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }

  .fundraising__block {
    display: none;

    .event-id {
      display: none;
    }

    .body-only {
      display: none;
    }

    .join-button {
      display: none;
    }

    .top-right {
      top: -3%;
      right: -3%;

      @media (min-width: $small) {
        right: -7%;
      }
    }

    progress {
      background-color: #E4EAF0;
    }

    progress,
    progress[value] {
      height: 100%;
      width: 100%;
      z-index: 3;
      appearance: none;
      border: none;
    }

    progress[value]::-webkit-progress-bar {
      background-color: #E4EAF0;
    }

    progress[value]::-moz-progress-bar {
      background-color: $sbf-dark-green;
    }

    progress::-webkit-progress-value {
      background-color: $sbf-dark-green;
    }

    .mypage__fundraising {
      width: 87%;
    }

    .fundraiser_progress-arrow {
      background-color: $sbf-dark-green;
      z-index: 3;
      position: absolute;
      top: 0;
      width: 9px;
      height: 110%;
      transform: skew(-17deg);
    }

    .fundraising__goal,
    .fundraising__raised {
      z-index: 3;
      color: $sbf-white;
      top: -0.2em;
      position: absolute;

      @media (min-width: $small) {
        margin: 0;
      }
    }

    .fundraising__goal {
      right: 0;

      @include sbf-heading('headline-12-sans');

      padding-top: 3px;
    }

    .fundraising__raised {
      left: 0;
      font-size: 22px; // no TK mixin for this

      @media (min-width: $medium) {
        font-size: 28px; // no TK mixin for this
      }
    }

    .donate-button {
      @include sbf-button($btnType: 'sbf-dark-green');

      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      max-width: 200px;
    }

    @media (max-width: $medium - 1) {
      display: block;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      width: 100%;
      bottom: 50px;

      .fundraising--desktop {
        display: none;
      }
    }
  }
}

.mypage__description {
  @media (min-width: $medium) {
    max-width: 425px;
  }
}

.mypage__team {
  @include link($sbf-blue);

  border: none;
  text-decoration: underline;
  display: block;

  @media (min-width: $medium) {
    display: inline-block;
  }

  &.is-editable {
    @include link($sbf-mint-green);

    text-decoration: none;
  }

  .tk-editable-ui {
    display: inline-block;
    border-radius: 50%;

    .icon {
      width: 12px;
      height: 12px;
      position: relative;
      top: -2px;

      @media (min-width: $medium) {
        top: -3px;
      }
    }
  }
}

.mypage-header__flag {
  color: #FFF;

  h1 {
    margin: 0;
  }

  .mypage--pics {
    overflow-x: hidden;

    .before-photo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 200px;
      background-color: #FFF;
      box-shadow: 0 0 0 1px #CCC;
      padding: 3px;
      position: absolute;
      left: 101%;
      top: 0;
      transition: left 500ms ease;
      z-index: 2;

      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        padding: 0;
        box-shadow: none;
      }
    }

    .after-photo {
      transform: scale(1);
      transition: transform 500ms ease, -webkit-transform 500ms ease;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 200px;
      background-color: #FFF;
      box-shadow: 0 0 0 1px #CCC;
      padding: 3px;

      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        padding: 0;
        box-shadow: none;
      }
    }

    &.before {
      .before-photo {
        left: 0;
        transform: scale(1);
      }

      .after-photo {
        transform: scale(0.8);
      }
    }

    .photo-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 200px;
      background-color: #FFF;
      box-shadow: 0 0 0 1px #CCC;
      padding: 3px;

      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        padding: 0;
        box-shadow: none;
      }
    }
  }
}

.mypage-header__switch {
  position: absolute;
  display: block;
  width: 42px;
  height: 20px;
  margin: 15px 0 0;
  left: 80px;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $sbf-white;
    transition: 0.4s;

    &::before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      right: -1px;
      bottom: 0;
      background-color: $sbf-white;
      transition: 0.4s;
    }

    &.round {
      border-radius: 34px;

      &::before {
        border-radius: 50%;
        background-color: $sbf-green;
      }
    }
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $sbf-green;
  }

  input:checked + .slider::before {
    transform: translateX(-26px);
    background-color: $sbf-light-blue;
  }
}

.mypage-header__event-info {
  max-width: 400px;
}

.old-header {
  margin-bottom: 58px;

  @media (min-width: $medium) {
    margin: 0;
  }

  @media (min-width: $medium) {
    margin-bottom: 20px;
  }
}

// *------------------------------------*\
//   # Fundraising Block Shared Styles
//\*------------------------------------*/

@import 'sections/fundraising';
@import 'sections/milestones';

// *------------------------------------*\
//   # Mobile Header Styles
//\*------------------------------------*/

.mobile--pics {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vw;
  overflow: hidden;

  img {
    min-width: 100%;
    height: 100%;

    // hide the default photos - do not use as mobile background
    &[data-id=''] {
      display: none;
    }
  }

  .photo-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 100%;
      max-width: 100%;
      min-width: auto;
      width: auto;
      height: auto;
    }

    &.toggle-switch {
      @extend .mobile-toggle;

      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform-origin: bottom right;
      transform: scale(0.15) translate(-200px, -300px);
      z-index: 3;
      overflow: hidden;

      @media (min-width: 375px) {
        transform: scale(0.15) translate(-270px, -300px);
      }

      @media (min-width: $small) {
        transform: scale(0.15) translate(-370px, -370px);
      }

      .photo-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        img {
          max-height: 100%;
          max-width: 100%;
        }
      }

      figcaption {
        display: none;
      }
    }

    .hidden-before-photo,
    .hidden-after-photo {
      display: none;
      width: auto;
      height: auto;
      min-width: auto;
      min-height: auto;
      max-width: none;
      max-height: none;
      padding: 0;
    }
  }
}

// mobile toggle
.mobile-toggle {
  border: 10px solid $sbf-white;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 3;
  cursor: pointer;
}

// Hide Sidebar Things on mobile to display elsewhere
.mypage_stat_callout {
  display: block !important;

  @include sbf-heading('headline-24-sans');

  a {
    @include link($sbf-blue);

    text-decoration: underline;
    border: none;
  }

  &::after {
    content: '';
    background-image: url('sbf-hr-gray.png');
    background-repeat: no-repeat;
    background-position: center center;
    height: 36px;
    max-width: 90%;
    display: block;
    margin: 10px auto 0;
  }

  @media (min-width: $medium - 1) {
    &::after {
      display: none;
    }
  }
}

.sidebar-stats,
.national-partner {
  display: none;

  @media (min-width: $medium) {
    display: block;
  }
}

.mobile-only {
  display: block;

  @media (min-width: $medium) {
    display: none;
  }

  // Mobile Join Button
  .join-button {
    @include sbf-button($btnType: "sbf-blue");

    min-width: 200px;
    text-align: center;
    width: 200px;
    margin: 10px auto;
    display: block;
  }

  .event-info {
    text-align: center;
    margin: 20px auto;

    p {
      @include sbf-body('small-sans');

      margin-bottom: 0;
    }

    a {
      @include link($sbf-blue);

      text-decoration: underline;
      font-weight: 600;
      border-bottom: none;
    }
  }
}

// *------------------------------------*\
//   # Website Message
//\*------------------------------------*/

.mypage__message {
  background: $sbf-white;
  border-top: 1px solid #D6D6D6;
  border-bottom: 1px solid #D6D6D6;
  text-align: center;
  padding: 30px 24px;
  margin-top: 16px;
  margin-bottom: 12px;

  @media (min-width: $medium - 1) {
    background: none;
    border: none;
    text-align: left;
    padding: 0;
  }
}

/* Adds some spacing around league badge, if it is present.
See about_spacing in about block */

@media (min-width: $medium) {
  .about {
    min-height: 60px;
  }
}

.team-message {
  display: none;

  @media (min-width: $medium) {
    display: block;
  }

  h2 {
    font-family: $serifFamily;

    @media (min-width: $medium) {
      font-family: $sansSerifFamily;
    }
  }

  .mobile-only & {
    display: block;
    margin-bottom: 24px;

    @media (min-width: $medium) {
      display: none;
    }
  }
}

// *------------------------------------*\
//   # Participant Roles
//\*------------------------------------*/

.mypage__roles {
  margin-bottom: 20px;

  li {
    @include sbf-body('small-sans');

    display: inline;
  }

  li::after {
    content: " \2022";
  }

  li:last-child::after {
    content: none;
    padding: 30px 0;
  }
}

.roles__header {
  @include sbf-heading('headline-24-sans');

  text-align: center;
  display: block;
  margin-bottom: 6px;
}

.roles__list {
  text-align: center;
  list-style: none;
  margin: 0;
  display: inline-block;
  width: 100%;
}

@media (min-width: $medium) {
  .roles__header {
    display: inline;
    text-align: left;
    margin-right: 12px;
  }

  .roles__list {
    text-align: left;
    width: auto;
  }
}

// *------------------------------------*\
//   # Media Querys
//\*------------------------------------*/

@media (max-width: $medium - 1) {
  .mypage-header {
    padding-top: 20px;
    width: calc(100% + 14px);
    height: calc(100vw + 162px);
    background-size: cover;
    background-position: center top;
    background-color: $sbf-black;

    &::after,
    &::before {
      height: 100%;
    }
  }

  .participant-id {
    &.mobile-only { display: block; }
    &.desktop-only { display: none; }
  }

  .mypage-header__flag {
    padding-top: 16px;
    color: #FFF;

    h2 {
      margin-top: 8px;
    }
  }

  .flag__image,
  .mypage-header__event-info.desktop-only {
    display: none;
  }

  .mobile--pics {
    display: block;
  }

  .mobile-stats {
    display: block;
    background: $sbf-white;
    border-top: 1px solid #D6D6D6;
    border-bottom: 1px solid #D6D6D6;
    padding: 0 24px 30px;
    margin: 0 -7px;
  }
  .mobile-stats:empty { display: none; }

  .mypage__content {
    margin: 0 -7px;
  }

  .mypage__main-column {
    padding: 0;

    h2 {
      @include sbf-body('default-serif');

      font-weight: 600;
      padding: 0 24px;
      text-align: center;
    }
  }

  .mypage__sidebar {
    .fundraising__block {
      display: none;
    }
  }

  .header-serif {
    font-family: $serifFamily;
  }
} // end media query

.mypage__sidebar {
  .fundraising__block {
    @media (min-width: $large) {
      margin-top: 50px;
    }
  }
}

.not_applicable {
  display: none;
}

// Import Sticky Header Last
@import 'sticky-header';

// Internal page styles
.mypage--donors,
.mypage--gallery {
  .mypage__main-column {
    h2 {
      font-family: $sansSerifFamily;
    }
  }
}

@media (min-width: $medium) {
  .mypage--gallery {
    .mypage__main-column {
      h2 {
        margin-bottom: 0;
      }

      .back-to-mypage {
        margin: 12px 0 8px 0;
        padding-top: 8px;
      }
    }
  }
}

.responsive-video {
  margin: 0;
  padding: 0 0 56.25% 0;
}

.private-event-tooltip-popoup {
  display: none;
}

.photo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 87px;
  height: 87px;
  background-color: #FFF;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}
