@import "~sbf-design-system/scss/base";

// milestone variables
$milestone-bar-color: #E4EAF0;
$milestone-progress-color: $sbf-dark-green;
$milestone-incomplete-color: $sbf-light-blue;
$milestone-complete-color: $sbf-gold;
$milestone-size: 36px;

.mypage__content {
  padding: 36px 0;

  @media (min-width: $medium) {
    padding: 45px 0;
  }

  .milestone__block.is-editable {
    @media (min-width: $medium) {
      margin-top: 35px;
    }
  }

  .milestone__block {
    display: block;
    padding: 0 12px;

    @media (min-width: $medium) {
      padding: 0 16px 0 6px;
    }

    @media (min-width: $large) {
      padding: 0 15px 0 8px;
    }

    @media (min-width: 1100px) {
      padding: 0 24px 0 15px;
    }

    h2 {
      @include sbf-heading('headline-30-sans');

      font-weight: 600;
      display: inline-block;
      margin-right: 15px;
    }

    ul {
      list-style-type: none;
    }
  }
}

.milestone__amounts {
  display: flex;
  justify-content: space-between;
}

.milestone__raised {
  .milestone__raised-amount,
  .milestone__currency {
    @include sbf-body('default-sans');

    color: $sbf-dark-green;
    font-weight: bold;
  }

  .milestone__currency {
    margin-right: -3px;
  }
}

.milestone__goal {
  @include sbf-body('mini-sans');

  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  top: -50px;

  .milestone__goal-amount {
    margin-right: 2px;
  }
}

.milestone__progress-bar {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 36px;
  width: 100%;
  height: 30px;
}

.milestone__progress-bar--mobile {
  margin: 0 2px 0 12px;

  &.stretch.goal-complete {
    margin: 0 8px 0 12px;
  }

  &.stretch.goal-incomplete {
    margin: 0 2px 0 12px;
  }
}

.progress__bar-container {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: $milestone-size;
}

.progress__bar {
  align-self: center;
  position: absolute;
  top: calc($milestone-size / 2);
  transform: translateY(-50%);
  width: 100%;
  max-width: 100%;
  height: 12px;
  background-color: $milestone-bar-color;

  &.incomplete.stretch {
    background-color: $milestone-bar-color;
    width: 88%;
  }

  &.incomplete.stretch.goal-reached {
    background-color: $milestone-complete-color;
    width: 75%;

    @media (min-width: $small) {
      width: 88%;
    }
  }

  &.stretch,
  &.goal-reached {
    background-color: $milestone-complete-color;
    width: 100%;
  }

  &.progress.stretch.goal-reached {
    background-color: $milestone-progress-color;
  }

  &.stretch.goal-reached {
    background-color: $milestone-bar-color;
    width: 100%;
  }

  &.progress {
    background-color: $milestone-progress-color;
  }

  &.stretch ~ .progress {
    max-width: 76%;

    @media (min-width: $small) {
      max-width: 88%;
    }
  }

  &.stretch ~ .progress.goal-reached {
    max-width: 50%;
  }
}

.no-stretch-goal {
  .progress__bar {
    width: 96%;

    @media (min-width: $medium) {
      width: 98%;
    }
  }
}

.milestone__items-container {
  position: absolute;
  height: $milestone-size;
  width: 100%;

  .milestone__item {
    position: absolute;
    width: $milestone-size;
    height: $milestone-size;
    cursor: pointer;
    border-radius: 50%;
    border: 4px solid #FFF;
    background-color: $milestone-incomplete-color;
    background-clip: content-box;
    transform: translateX(-50%);
    transition: all 0.25s ease-out;
    z-index: 3;

    .expanded-hover-area {
      height: 40px;
      width: 40px;
      position: absolute;
      background: transparent;
      color: transparent;
      top: 5px;
      right: -5px;
    }

    &.complete,
    &.goal-reached,
    &.goal-complete {
      background-color: $milestone-complete-color;

      &:hover,
      &:focus {
        background-color: darken($milestone-complete-color, 20%);
      }
    }

    &.incomplete {
      background-color: $milestone-incomplete-color;

      &:hover,
      &:focus {
        background-color: darken($milestone-incomplete-color, 20%);
      }
    }

    &.stretch,
    &.milestone-goal {
      left: 86%;

      @media (min-width: $small) {
        left: 88%;
      }
    }

    &.milestone-goal.stretch {
      ~ .milestone-goal {
        margin-left: -4px;

        @media (min-width: $small) {
          margin-left: 0;
        }

        @media (min-width: $medium) {
          margin-left: -4px;
        }
      }
    }

    &.milestone-goal.stretch.goal-reached {
      left: 50%;

      ~ .milestone-goal {
        left: 50%;
      }
    }

    &.milestone-stretch-goal.goal-incomplete {
      background: url('/icons/lock.svg') no-repeat;
      border: 0;
      border-radius: 0;
      top: 2px;
      width: 19px;
      height: 26px;

      &:hover,
      &:focus {
        opacity: 0.5;
      }

      @media (min-width: $small) {
        margin-left: 4px;
      }

      @media (min-width: $medium) {
        margin-left: 0;
      }

      svg {
        display: none;
      }

      .milestone__item-details {
        display: none;
      }
    }

    > svg {
      bottom: -4px;
      fill: $sbf-white;
      stroke: $sbf-white;
    }

    > .icon-ribbon {
      top: 7px;
      left: 6px;
    }

    > .icon-mirror {
      top: 6px;
      left: 6.25px;
    }

    > .icon-clippers {
      top: 5.5px;
      left: 10px;
    }

    > .icon-cycling {
      top: 4.5px;
      left: 4px;
    }

    > .icon-bow-tie {
      top: 8px;
      left: 5.5px;
    }

    > .icon-food {
      top: 6px;
      left: 9.25px;
    }

    > .icon-drink {
      top: 7px;
      left: 6px;
    }

    > .icon-microphone {
      top: 7px;
      left: 9.5px;
    }

    > .icon-dancing {
      top: 6px;
      left: 5.5px;
    }

    > .icon-running {
      top: 7px;
      left: 6.5px;
    }

    > .icon-gift {
      top: 5px;
      left: 3.5px;
      width: 20px;
      height: 20px;
    }

    > .icon-cash {
      top: 6px;
      left: 7px;
      stroke: none;
    }

    .icon-lock {
      fill: $sbf-black;
    }
  }

  &.no-stretch-goal {
    .milestone-goal {
      left: 97.5%;
      margin-left: -4px;

      @media (min-width: $small) {
        margin-left: 0;
      }

      @media (min-width: 600px) {
        left: 98%;
      }

      @media (min-width: $medium) {
        left: 99%;
        margin-left: -4px;
      }
    }
  }

  .milestone__item--goal {
    cursor: default;
    left: 97.5%;
    margin-left: -4px;

    @media (min-width: $small) {
      margin-left: 0;
    }

    @media (min-width: 600px) {
      left: 98%;
    }

    @media (min-width: $medium) {
      left: 99%;
      margin-left: -4px;
    }

    &.stretch,
    &.stretch.goal-complete {
      margin-right: 0;
    }

    &.goal-complete {
      left: 50%;

      &:hover,
      &:focus {
        background-color: $milestone-complete-color;
      }
    }

    .icon-cash {
      top: 4px;
      left: 5px;
      height: 16px;
      width: 18px;
    }
  }

  .milestone-stretch-goal.goal-complete:last-of-type {
    left: 97.5%;
    margin-left: -4px;

    @media (min-width: $small) {
      margin-left: 0;
    }

    @media (min-width: 600px) {
      left: 98%;
    }

    @media (min-width: $medium) {
      left: 99%;
      margin-left: -4px;
    }
  }
}

.milestone__item {
  position: absolute;
  transform: translate(-50%, 0);

  @mixin milestone-left( $mark ) {
    &__#{$mark} {
      left: #{$mark + '%'};

      .milestone__item-details,
      .editable-control-tooltip {
        left: 0;
      }

      .editable-control-tooltip::after {
        left: 2%;
        content: '';
        position: absolute;
        top: -5px;
        border-right: 10px solid #FFF;
        border-top: 10px solid #FFF;
        border-left: 10px solid transparent;
        border-bottom: 10px solid transparent;
        transform: rotate(-45deg);
        z-index: 1;
      }

      &.milestone,
      &.milestone-goal,
      &.milestone-stretch-goal.goal-complete {
        &:hover .milestone__item-details::after {
          left: 2%;
        }
      }
    }
  }

  @mixin milestone-right( $mark ) {
    &__#{$mark} {
      left: #{$mark + '%'};

      .milestone__item-details,
      .editable-control-tooltip {
        right: 0;
      }

      .editable-control-tooltip::after {
        right: 2%;
        content: '';
        position: absolute;
        top: -5px;
        border-right: 10px solid #FFF;
        border-top: 10px solid #FFF;
        border-left: 10px solid transparent;
        border-bottom: 10px solid transparent;
        transform: rotate(-45deg);
        z-index: 1;
      }

      &.milestone,
      &.milestone-goal,
      &.milestone-stretch-goal.goal-complete {
        &:hover .milestone__item-details::after {
          right: 2%;
        }
      }
    }
  }

  @mixin milestone-stretch-right( $mark ) {
    &__#{$mark} {
      left: #{$mark + '%'};

      .milestone__item-details,
      .editable-control-tooltip {
        right: 0;
      }

      .editable-control-tooltip::after {
        right: 2%;
        content: '';
        position: absolute;
        top: -5px;
        border-right: 10px solid #FFF;
        border-top: 10px solid #FFF;
        border-left: 10px solid transparent;
        border-bottom: 10px solid transparent;
        transform: rotate(-45deg);
        z-index: 1;
      }

      &.milestone,
      &.milestone-goal,
      &.milestone-stretch-goal.goal-complete {
        &:hover .milestone__item-details::after {
          right: 2%;
        }
      }
    }
  }

  &.milestone {
    // generate position
    @for $i from 0 to 101 {
      @if $i >= 0 and $i <= 10 {
        @include milestone-left($i);
      }

      @if $i > 10 and $i <= 20 {
        @include milestone-left($i);
      }

      @if $i > 20 and $i <= 30 {
        @include milestone-left($i);
      }

      @if $i > 30 and $i <= 40 {
        @include milestone-left($i);
      }

      @if $i > 40 and $i <= 50 {
        @include milestone-left($i);
      }

      @if $i > 50 and $i <= 60 {
        @include milestone-right($i);
      }

      @if $i > 60 and $i <= 70 {
        @include milestone-right($i);
      }

      @if $i > 70 and $i <= 80 {
        @include milestone-right($i);
      }

      @if $i > 80 and $i <= 90 {
        @include milestone-right($i);
      }

      @if $i > 90 and $i <= 100 {
        @include milestone-stretch-right($i);
      }
    }
  }

  &.milestone__0 {
    left: 1.25%;
    margin-left: 8px;

    @media (min-width: $small) {
      margin-left: 6px;
    }

    @media (min-width: $small) {
      margin-left: 4px;
    }

    @media (min-width: $large) {
      margin-left: 2px;
    }
  }

  &.milestone__1,
  &.milestone__2 {
    margin-left: 9px;

    @media (min-width: $medium) {
      margin-left: 6px;
    }

    @media (min-width: $large) {
      margin-left: 4px;
    }
  }
}

.stretch-goal__tooltip-container {
  position: absolute;
  cursor: pointer;
  width: 22px;
  top: 0;
  left: 93%;
  opacity: 0;
  z-index: 3;

  @media (min-width: $small) {
    left: 94%;
  }

  .stretch-goal__tooltip-item {
    display: block;
    border-top: 1px solid #EEE;
    z-index: 1;
  }

  .stretch-goal__tooltip-item:first-child {
    border-top: none;
  }

  &.open {
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }

  &:hover .stretch-goal__tooltip-item:first-child {
    &::after {
      content: '';
      position: absolute;
      top: -5px;
      right: 8px;
      border-right: 10px solid #FFF;
      border-top: 10px solid #FFF;
      border-left: 10px solid transparent;
      border-bottom: 10px solid transparent;
      transform: rotate(-45deg);
      z-index: 1;
    }
  }
}

.stretch-goal__tooltip-item {
  @include sbf-body('mini-sans');

  bottom: -45px;
  right: 155px;
  width: 180px;
  padding: 16px;
  box-shadow: 0 2px 10px 0 #CCC;
  background-color: #FFF;
  text-align: center;
  z-index: 1;

  p {
    margin-bottom: 0;
  }
}

.stretch-goal__tooltip-item:last-of-type {
  box-shadow: 0 4px 10px 0 #CCC;
}

.stretch-goal__tooltip-item:nth-of-type(1) {
  box-shadow: 0 -2px 10px 0 #CCC;
}

.milestone,
.milestone-goal,
.milestone-stretch-goal.goal-complete,
.expanded-hover-area {
  .milestone__item-details {
    @include sbf-body('mini-sans');

    position: absolute;
    top: 150%;
    width: 180px;
    padding: 16px;
    box-shadow: 0 0 10px 2px #CCC;
    background-color: #FFF;
    text-align: center;
    opacity: 0;
    z-index: 1;

    p {
      margin-bottom: 0;
    }
  }

  &:hover .milestone__item-details {
    opacity: 1;

    &::after {
      content: '';
      position: absolute;
      top: -5px;
      border-right: 10px solid #FFF;
      border-top: 10px solid #FFF;
      border-left: 10px solid transparent;
      border-bottom: 10px solid transparent;
      transform: rotate(-45deg);
      z-index: 1;
    }
  }
}

.milestone__item-details {
  p {
    word-break: break-word; /* Chrome, Safari */
    word-wrap: break-word; /* IE11, Firefox */
  }
}

.fundraiser_mypage {
  .milestone__block {
    display: none;

    @media (min-width: $medium) {
      display: block;
      padding: 0 24px;
    }

    @media (min-width: $large) {
      padding: 0 22px 0 8px;
    }

    @media (min-width: 1210px) {
      padding: 0 24px 0 15px;
    }
  }
}

.mobile-fundraiser-milestones,
.mobile-event-milestones,
.mobile-team-milestones {
  display: block;
  padding: 0 12px;
  margin: 0 -27px;

  @media (min-width: $medium - 1) {
    display: none;
  }

  .milestone__block {
    text-align: left;
  }

  h2 {
    @include sbf-heading('headline-30-sans');

    display: inline-block;
    margin-right: 15px;
  }

  ul {
    list-style-type: none;
  }

  .button.cancel {
    color: $sbf-blue;
  }
}
