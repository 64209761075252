// Global Typography Variables

// Font Family
$sourceSans: 'Source Sans 3', 'Helvetica Neue', Arial, Helvetica, sans-serif;
$zillaSlab: 'Zilla Slab', Georgia, serif;

$sansSerifFamily: $sourceSans;
$serifFamily: $zillaSlab;

$defautFontFamily: $sansSerifFamily;

// Font Size
$baseFontSize: 16px;
$largeFontSize: $baseFontSize * 1.25; // ~20px
$smallFontSize: $baseFontSize * 0.75; // ~12px

// Line Heights
$baseLineHeight: 1.5;
$largeLineHeight: 1.33333;

// Margins
$baseBottomMargin: 24px;

// Misc Styles
$sbf-fw-600: 600;

// Global Breakpoints

$small: 480px;
$medium: 760px;
$large: 900px;
$x-large: 1080px;

// Global Color Variables

// Main Colors
$sbf-black: #353535;
$sbf-blue: #005595; // Default Blue
$sbf-brown: #943F2B;
$sbf-gold: #B4975A; // Border Gold
$sbf-green: #63883D; // Default Green
$sbf-orange: #E58424;
$sbf-peach: #F4966E;
$sbf-pink: #C07493;
$sbf-purple: #562363;
$sbf-red: #CD2617;
$sbf-tan: #CA8A7C;
$sbf-white: #F5F5F5;
$sbf-yellow: #F3D36E;
$sbf-grey: #D8D8D8;

// Lighter Color Variations
$sbf-light-green: #93C45B;
$sbf-light-blue: #3588C8;
$sbf-light-orange: #F4BA6C;
$sbf-light-purple: #A66EAE;

// Darker Color Variations
$sbf-dark-green: #1C6B3D;
$sbf-dark-yellow: #E9AF1F; // Currently being used in a lot of advocacy areas
$sbf-dark-orange: #C95000;
$sbf-dark-pink: #81254A;
$sbf-dark-grey: #979797;

// Editable UI Colors
$sbf-mint-green: #11A05B; // Currently used for edit buttons

// Color Alias
$sbf-edit-green: $sbf-mint-green;

/* SBF Brand Colors / SBF Palette / SBF Text Colors - WIP */

// SBF Brand Colors
$sbf-brand-blue: #005595;
$sbf-brand-green: #63883D;
$sbf-brand-gold: #B4975A;

// SBF State Colors
$sbf-state-focus: #0790FF;
$sbf-state-required: #CD2617;
$sbf-state-error: #CD2617;
$sbf-state-error-bg: #FAEBE8;
$sbf-state-success: #11A05B;
$sbf-state-success-bg: #F4F9EF;
$sbf-state-info: #005595;
$sbf-state-info-bg: #EBF3F9;
$sbf-state-warning: #E9AF1F;
$sbf-state-warning-bg: #FDF6E2;

// SBF Palette - Gray
$sbf-palette-white: #FFF;
$sbf-palette-gray-100: #F5F5F5;
$sbf-palette-gray-200: #E5E5E5;
$sbf-palette-gray-300: #D8D8D8;
$sbf-palette-gray-400: #979797;
$sbf-palette-gray-500: #6E6E6E;
$sbf-palette-black: #353535;

// SBF Palette - Green
$sbf-palette-green-100: #F4F9EF;
$sbf-palette-green-200: #E7F5EF;
$sbf-palette-green-300: #D3E3C2;
$sbf-palette-green-400: #93C45B;
$sbf-palette-green-500: #11A05B;
$sbf-palette-green-600: #63883D;
$sbf-palette-green-700: #1C6B3D;

// SBF Palette - Blue
$sbf-palette-blue-100: #EBF3F9;
$sbf-palette-blue-200: #CDE5FF;
$sbf-palette-blue-300: #70BFFF;
$sbf-palette-blue-400: #3588C8;
$sbf-palette-blue-500: #005595;

// SBF Palette - Gold
$sbf-palette-gold-100: #EFE9DC;
$sbf-palette-gold-200: #D5C4A0;
$sbf-palette-gold-300: #B4975A;

// SBF Palette - Orange
$sbf-palette-orange-100: #FDF1E2;
$sbf-palette-orange-200: #F4BA6C;
$sbf-palette-orange-300: #F4966E;
$sbf-palette-orange-400: #E58424;
$sbf-palette-orange-500: #C95000;

// SBF Palette - Yellow
$sbf-palette-yellow-100: #FDF6E2;
$sbf-palette-yellow-200: #F3D36E;
$sbf-palette-yellow-300: #E9AF1F;

// SBF Palette - Red
$sbf-palette-red-100: #FAEBE8;
$sbf-palette-red-200: #CD2617;

// SBF Palette - Brown
$sbf-palette-brown-100: #CA8A7C;
$sbf-palette-brown-200: #943F2B;

// SBF Palette - Purple
$sbf-palette-purple-100: #F6F0F7;
$sbf-palette-purple-200: #E2C3EA;
$sbf-palette-purple-300: #A66EAE;
$sbf-palette-purple-400: #562363;

// SBF Palette - Pink
$sbf-palette-pink-100: #FEEFF7;
$sbf-palette-pink-200: #FAC7E1;
$sbf-palette-pink-300: #C07493;
$sbf-palette-pink-400: #81254A;

// SBF Text Colors
$sbf-text-dark: #353535;
$sbf-text-light: #FFF;

// Button Variants
$btnTypes: (
  "sbf-black": $sbf-black,
  "sbf-blue": $sbf-blue,
  "sbf-brown": $sbf-brown,
  "sbf-gold": $sbf-gold,
  "sbf-green": $sbf-green,
  "sbf-orange": $sbf-orange,
  "sbf-peach": $sbf-peach,
  "sbf-pink": $sbf-pink,
  "sbf-purple": $sbf-purple,
  "sbf-red": $sbf-red,
  "sbf-tan": $sbf-tan,
  "sbf-yellow": $sbf-yellow,
  "sbf-light-green": $sbf-light-green,
  "sbf-light-blue": $sbf-light-blue,
  "sbf-light-orange": $sbf-light-orange,
  "sbf-light-purple": $sbf-light-purple,
  "sbf-dark-green": $sbf-dark-green,
  "sbf-dark-yellow": $sbf-dark-yellow,
  "sbf-dark-orange": $sbf-dark-orange,
  "sbf-dark-pink": $sbf-dark-pink,
  "sbf-mint-green": $sbf-mint-green,
  "sbf-edit-green": $sbf-edit-green
);
