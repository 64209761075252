$svg-icon-path: "images/";

@import "~sbf-design-system/scss/base";
$gray: #CED1D6;

.mypage__fundraising {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  text-align: left;
  margin: 0 auto;

  .button {
    flex: 1;
    text-align: center;
    margin-bottom: 24px;

    &.mobile-only {
      margin: 0 20px 10px 0;
    }
  }

  .join-button {
    @include sbf-button($btnType: "sbf-blue", $btnSize: "small");
  }

  .donate-button {
    @include sbf-button($btnType: "sbf-dark-green", $btnSize: "small");
  }

  @media (max-width: $medium) {
    max-width: 350px;
  }

  @media (min-width: $large) {
    padding: 16px;
    background: $sbf-white;
    border: 1px solid #DBDBDB;
    margin: 0;
  }
}

// Recent Donor Styles || Internal Page
.fundraising__list {
  margin-top: 0;

  h2 + a {
    @include sbf-body('mini-sans');

    font-weight: 700;
    border-bottom: none;

    .icon {
      width: 16px;
      height: 21px;
      vertical-align: top;
      margin: 1px 2px 0 0;
    }
  }

  h3 {
    color: $sbf-blue;
  }

  ol {
    counter-reset: none;
  }

  li {
    @include sbf-body('default-sans');

    font-weight: 700;
    counter-increment: none;
    padding: 10px 0;
    border-bottom: 1px solid $gray;
    display: flex;
    flex-flow: row nowrap;

    &::before {
      content: none;
    }

    &:first-child {
      border-top: 1px solid $gray;
    }

    span.dash {
      flex: 1;
    }

    span.donor__name {
      width: 80%;
    }
  }

  .spinner {
    margin: 10px auto 3em;
  }
}

.list-body {
  padding: 0 20px;

  @media (min-width: $medium) {
    padding: 0;
  }
}

.list-header {
  background-color: $sbf-white;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  margin: 30px -23px;
  padding: 4px 0 0 0;

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  @media (min-width: $medium) {
    background: transparent;
    margin: 0 0;
    border: none;
  }

  .condensed-header {
    @include sbf-heading('headline-24-sans');

    text-align: center;
    display: block;
    clear: both;

    @media (min-width: $medium) {
      float: left;
    }
  }
}

// is this a good idea?
.fundraising__list .list-header .condensed-header,
.event__lists .list-header .condensed-header {
  margin: 26px 0 16px 0;

  @media (min-width: $medium) {
    float: left;
    margin: 0 0 16px 0;
    width: 100%;
    text-align: left;
  }

  @media (min-width: $large) {
    width: auto;
    margin-bottom: 0;
  }
}

.back-to-mypage {
  @include sbf-back-button;

  text-align: center;
  text-decoration: none;
  display: block;
  clear: both;

  @include sbf-body('small-sans');

  @media (min-width: $medium) {
    float: left;
    margin: 0 0 15px;
    padding-left: 0;
    clear: left;
  }

  @media (min-width: $large) {
    padding-top: 8px;
  }

  .icon {
    width: 16px;
    height: 21px;
    vertical-align: top;
    margin: 1px 2px 0 0;
  }
}

.mypage__search {
  @include sbf-inline-input;

  display: block;
  width: 75%;
  margin: 0 auto;
  padding: 4px 0 4px 0;

  @media (min-width: $medium) {
    display: inline-block;
    width: 250px;
    float: left;

    @include clearfix;
  }

  @media (min-width: $large) {
    float: right;
    padding-top: 0;
  }

  .search_button {
    transition: transform 200ms ease-out;
    right: 10px;
    top: 16px;

    @media (min-width: $large) {
      top: 13px;
    }
  }

  .search_button .icon {
    height: 28px;
    width: 28px;
    transform: scale(0.57);
    transform-origin: center;
    transition: transform 200ms ease-out;
  }
}

// Headers under list-body
.condensed-header {
  margin-bottom: 0.5em;
}

.empty-filter-message {
  border-top: 0;

  @media (min-width: $medium) {
    padding: 0.5em 0;
    border-top: 1px solid $gray;
  }
}

.about__message {
  padding: 0 24px 30px;

  @media (min-width: $medium) {
    padding: 0;
  }

  p {
    @include sbf-body('small-serif');

    @media (min-width: $medium) {
      @include sbf-body('default-serif');
    }
  }

  .list-header .condensed-header {
    margin: 30px 0 8px 0;

    @media (min-width: $medium) {
      margin: 0 0 8px 0;
    }
  }
}
