@import "~sbf-design-system/scss/base";
// League Statuses

.league-info {
  flex-wrap: wrap;
  justify-content: space-between;
  background: $sbf-white;
  border: 1px solid #DBDBDB;
  text-align: center;
  margin: 25px 0 36px;

  @media (min-width: $small) {
    text-align: left;
  }
}

.league-status {
  padding-top: 15px;

  p {
    @include sbf-body('small-sans');

    padding: 8px 24px 0;

    @media (min-width: $small) {
      margin-left: 160px;
      padding: 5px 20px 0 0;
      text-align: left;
    }
  }

  h2,
  a:link {
    @include sbf-heading('headline-24-sans');

    @media (min-width: $small) {
      margin-left: 160px;
    }
  }

  a:link {
    margin-bottom: 0;
    border-bottom: 1px solid transparent;
    text-decoration: underline;
    display: block;
  }

  @media (min-width: $small) {
    padding-top: 25px;
  }
}

.mypage__main-column .league-status h2 {
  @include sbf-heading('headline-24-sans');

  margin-bottom: 3px;
  padding: 0;

  @media (min-width: $small) {
    margin-left: 160px;
    text-align: left;
  }
}

.league-link- {
  &squire {
    color: $sbf-blue;
  }

  &knight {
    color: $sbf-dark-green;
  }

  &knight_commander {
    color: $sbf-dark-pink;
  }

  &crusader {
    color: $sbf-gold;
  }

  &baron {
    color: $sbf-purple;
  }
}

.badge {
  background-repeat: no-repeat;
  background-size: contain;
}

.badge_small {
  margin: 0 3px 0 17px;
  float: left;
  height: 70px;
  width: 70px;
  display: none;

  @media (min-width: $medium) {
    margin: 0 10px 0 -8px;
    display: block;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

.badge_medium {
  display: inline-block;
  margin-top: 25px;
  width: 122px;
  height: 122px;

  @media (min-width: $small) {
    position: absolute;
    margin: 30px 20px;
  }
}

.not_applicable {
  display: none;
}
