@import "~sbf-design-system/scss/base";

.overlay.mypage-single-photo-overlay,
.overlay.mypage-single-photo-overlay.lightbox {
  background: transparent;
  padding: 0;
  margin-top: 12px;
  box-shadow: none;
  text-align: center;
  overflow: hidden;
  bottom: 0;

  @media (min-width: $medium) {
    width: 90%;
    height: 90%;
    text-align: center;
    overflow-y: hidden;
  }

  img {
    width: 100%;
    padding: 12px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  .caption-wrapper {
    background: rgba(0, 0, 0, 0.9);
    color: white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 8px;

    &.empty {
      display: none; // this gets overridden in the editable stylesheet
    }

    [contenteditable] {
      min-height: 2em;
    }
  }

  .show-next-photo,
  .show-prev-photo {
    @include sbf-body('large-sans');

    font-weight: 500;
    position: absolute;
    left: 0;
    top: 48%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    z-index: 1;
    border-radius: 0 2px 2px 0;
    padding: 6px;
  }

  .show-next-photo {
    left: auto;
    right: 0;
    border-radius: 2px 0 0 2px;
  }
}
