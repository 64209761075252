// General Functions
@function getUnit($value) {
  // Get unit from $value
  $unit: unit($value);

  @return $unit;
}

$parent-context: $baseFontSize;

@function em($pixels, $context: $parent-context) {
  // Convert px to em
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return calc($pixels / $context * 1em);
}

@function smartFontSize($font-size, $em-reference) {
  // Check unit and return `font-size`
  // Convert px to em if applicable or pass through the value
  @if (getUnit($font-size) == px) {
    $em-font-size: em($font-size, $em-reference);

    @return $em-font-size;
  }

  @else {
    @return $font-size;
  }
}

// Unitless Line Height
@function smartLineHeight($line-height, $font-size) {
  // Check unit and return `line-height`
  // Convert px to the unitless equivilent of ems if applicable or pass through the value
  @if (getUnit($line-height) == px) {
    $em-line-height: em($line-height, $font-size);

    @return calc($em-line-height / 1em);
  }

  @else {
    @return $line-height;
  }
}

// Font Weight Mixin
@mixin font-weight($bold:null, $weight:null) {
  // you can either pass a boolean using $bold
  // OR pass a specific font-weight value using the $weight variable.
  @if ($weight!=null) {
    font-weight: $weight;
  }

  @else if ($bold==true) {
    font-weight: 700;
  }

  @else {
    font-weight: normal;
  }
}

// Primary Typography Mixin
@mixin sbf-type($family: 'zillaslab', $type: 'body', $font-size: $baseFontSize, $line-height: 1.5em, $em-reference: $baseFontSize, $bold:null, $italic:null, $letter-spacing:null, $font-weight:null) {
  font-size: smartFontSize($font-size, $em-reference);
  line-height: smartLineHeight($line-height, $font-size);

  // Set font-family
  @if ($family == 'sourcesans') {
    font-family: $sansSerifFamily;                      // Source Sans 3
  }

  @else if ($family == 'zillaslab') {
    font-family: $serifFamily;                          // Zilla Slab
  }

  @if ($type == 'body') {
    // Letter-spacing adjustments for body content

    @if ($family == 'sourcesans') {
      @if ($letter-spacing!=null) {
        letter-spacing: $letter-spacing;
      }

      @else if ($font-size <= 18px) {
        letter-spacing: normal;
      }

      @else if ($font-size <= 24px) {
        letter-spacing: -0.005em;
      }

      @else {
        @debug "The font-size specified (#{$font-size}) is larger then our mixin curently supports (24px). letter-spacing will have to be set manually."; }
    }

    @else if ($family == 'zillaslab') {
      @if ($letter-spacing!=null) {
        letter-spacing: $letter-spacing;
      }

      @else if ($font-size <= 16px) {
        letter-spacing: 0.01em;
      }

      @else if ($font-size <= 18px) {
        letter-spacing: 0.005em;
      }

      @else if ($font-size <= 24px) {
        letter-spacing: -0.0015em;
      }

      @else {
        @debug "The font-size specified (#{$font-size}) is larger then our mixin curently supports (24px). letter-spacing will have to be set manually."; }
    }
  }

  @else if ($type == 'heading') {
    // Letter-spacing adjustments for headings
    // The letter-spacing adjustments are specified per font. (Source Sans 3 and Zilla Slab)

    // Source Sans 3 letter-spacing adjustments
    @if ($family == 'sourcesans') {
      @if ($letter-spacing!=null) {
        letter-spacing: $letter-spacing;
      }

      @else if ($font-size <= 12px) {
        letter-spacing: 0.21em;
      }

      @else if ($font-size <= 18px) {
        letter-spacing: -0.008em;
      }

      @else if ($font-size <= 24px) {
        letter-spacing: -0.015em;
      }

      @else if ($font-size <= 40px) {
        letter-spacing: -0.025em;
      }

      @else if ($font-size <= 100px) {
        letter-spacing: -0.035em;
      }

      @else {
        @debug "The font-size specified (#{$font-size}) is larger then our mixin curently supports (100px). letter-spacing will have to be set manually."; }

      // Zilla Slab letter-spacing adjustments
    }

    @else if ($family == 'zillaslab') {
      @if ($letter-spacing!=null) {
        letter-spacing: $letter-spacing;
      }

      @else if ($font-size <= 24px) {
        letter-spacing: -0.0001em;
      }

      @else if ($font-size <= 100px) {
        letter-spacing: -0.0075em;
      }

      @else {
        @debug "The font-size specified (#{$font-size}) is larger then our mixin curently supports (100px). letter-spacing will have to be set manually."; }
    }
  }

  // Set `font-weight` if applicable.
  @if ($bold!=null) {
    @if ($type == 'heading' and $family == 'zillaslab') {
      @include font-weight($weight: 500);
    }

    @else {
      @include font-weight($bold);
    }
  }

  @else if ($font-weight!=null) {
    @include font-weight($font-weight);
  }

  // Set `font-style: italic` if applicable.
  @if ($italic ==true) {
    font-style: italic;
  }
}

// Developer Convenience References
@mixin sbf-heading($name: 'headline-40-sans', $em-reference: $baseFontSize, $margin: 'default') {

  // Set margin if applicable, or default to `0px inherit 24px inherit`.
  // Use `null` to not declare a margin.

  @if ($margin == 'default') {
    margin-top: 0;
    margin-bottom: 24px;
  }

  @else {
    margin: $margin;
  }

  // Source Sans 3
  @if ($name == 'headline-100-sans') {
    @include sbf-type($type: 'heading', $family: 'sourcesans', $font-size: 100px, $line-height: 105px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name == 'headline-80-sans') {
    @include sbf-type($type: 'heading', $family: 'sourcesans', $font-size: 80px, $line-height: 85px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name == 'headline-60-sans') {
    @include sbf-type($type: 'heading', $family: 'sourcesans', $font-size: 60px, $line-height: 65px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name == 'headline-50-sans') {
    @include sbf-type($type: 'heading', $family: 'sourcesans', $font-size: 50px, $line-height: 55px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name == 'headline-40-sans') {
    @include sbf-type($type: 'heading', $family: 'sourcesans', $font-size: 40px, $line-height: 45px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name == 'headline-30-sans') {
    @include sbf-type($type: 'heading', $family: 'sourcesans', $font-size: 30px, $line-height: 35px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name == 'headline-24-sans') {
    @include sbf-type($type: 'heading', $family: 'sourcesans', $font-size: 24px, $line-height: 28px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name =='headline-20-sans') {
    @include sbf-type($type: 'heading', $family: 'sourcesans', $font-size: 20px, $line-height: 28px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name == 'headline-18-sans') {
    @include sbf-type($type: 'heading', $family: 'sourcesans', $font-size: 18px, $line-height: 24px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name == 'headline-12-sans') {
    @include sbf-type($type: 'heading', $family: 'sourcesans', $font-size: 12px, $line-height: 18px, $bold: false, $em-reference: $em-reference);

    text-transform: uppercase;
  }

  @else if ($name == 'headline-12-sans-bold') {
    @include sbf-type($type: 'heading', $family: 'sourcesans', $font-size: 12px, $line-height: 18px, $bold: true, $em-reference: $em-reference);

    text-transform: uppercase;

    // Zilla Slab
  }

  @else if ($name == 'headline-100-serif') {
    @include sbf-type($type: 'heading', $family: 'zillaslab', $font-size: 100px, $line-height: 105px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name == 'headline-80-serif') {
    @include sbf-type($type: 'heading', $family: 'zillaslab', $font-size: 80px, $line-height: 85px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name == 'headline-60-serif') {
    @include sbf-type($type: 'heading', $family: 'zillaslab', $font-size: 60px, $line-height: 65px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name == 'headline-50-serif') {
    @include sbf-type($type: 'heading', $family: 'zillaslab', $font-size: 50px, $line-height: 55px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name =='headline-30-serif') {
    @include sbf-type($type: 'heading', $family: 'zillaslab', $font-size: 30px, $line-height: 35px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name == 'headline-40-serif') {
    @include sbf-type($type: 'heading', $family: 'zillaslab', $font-size: 40px, $line-height: 45px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name == 'headline-24-serif') {
    @include sbf-type($type: 'heading', $family: 'zillaslab', $font-size: 24px, $line-height: 28px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name =='headline-20-serif') {
    @include sbf-type($type: 'heading', $family: 'zillaslab', $font-size: 20px, $line-height: 28px, $bold: true, $em-reference: $em-reference);
  }

  @else if ($name =='headline-18-serif') {
    @include sbf-type($type: 'heading', $family: 'zillaslab', $font-size: 18px, $line-height: 24px, $bold: true, $em-reference: $em-reference);
  }

  @else {
    @error 'sbf-heading says: `#{$name}` doesn\'t exist.';
  }
}

@mixin sbf-body($name: 'default-serif', $em-reference: $baseFontSize) {

  // Source Sans 3
  @if ($name == 'default-sans') {
    @include sbf-type($type: 'body', $family: 'sourcesans', $font-size: 18px, $line-height: 24px, $em-reference: $em-reference);
  }

  @else if ($name == 'medium-sans') {
    @include sbf-type($type: 'body', $family: 'sourcesans', $font-size: 20px, $line-height: 26px, $em-reference: $em-reference);
  }

  @else if ($name == 'large-sans') {
    @include sbf-type($type: 'body', $family: 'sourcesans', $font-size: 24px, $line-height: 30px, $em-reference: $em-reference);
  }

  @else if ($name == 'large-quote-sans') {
    @include sbf-type($type: 'body', $family: 'sourcesans', $font-size: 24px, $line-height: 30px, $em-reference: $em-reference, $letter-spacing: -0.01em, $italic: true);
  }

  @else if ($name == 'small-sans') {
    @include sbf-type($type: 'body', $family: 'sourcesans', $font-size: 16px, $line-height: 22px, $em-reference: $em-reference);
  }

  @else if ($name == 'mini-sans') {
    @include sbf-type($type: 'body', $family: 'sourcesans', $font-size: 14px, $line-height: 18px, $em-reference: $em-reference);

    // Zilla Slab
  }

  @else if ($name == 'default-serif') {
    @include sbf-type($type: 'body', $family: 'zillaslab', $font-size: 18px, $line-height: 24px, $em-reference: $em-reference);
  }

  @else if ($name == 'medium-serif') {
    @include sbf-type($type: 'body', $family: 'zillaslab', $font-size: 20px, $line-height: 26px, $em-reference: $em-reference);
  }

  @else if ($name == 'large-serif') {
    @include sbf-type($type: 'body', $family: 'zillaslab', $font-size: 24px, $line-height: 30px, $em-reference: $em-reference);
  }

  @else if ($name == 'large-quote-serif') {
    @include sbf-type($type: 'body', $family: 'zillaslab', $font-size: 24px, $line-height: 30px, $em-reference: $em-reference, $letter-spacing: -0.005em, $italic: true);
  }

  @else if ($name == 'small-serif') {
    @include sbf-type($type: 'body', $family: 'zillaslab', $font-size: 16px, $line-height: 22px, $em-reference: $em-reference);
  }

  @else {
    @error 'sbf-body says: `#{$name}` doesn\'t exist.';
  }
}
