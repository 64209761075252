@import "~sbf-design-system/scss/base";
// Mypage Gallery Styles

.mypage__gallery {
  text-align: center;
  padding-bottom: 36px;
  margin-left: -7px;
  margin-right: -7px;
  margin-top: -1px;

  @media (min-width: $medium) {
    background-color: $sbf-white;
    border-top: 1px solid #DBDBDB;
    margin-left: -8px;
    margin-right: -8px;
  }

  .inner {
    width: 100%;
  }

  .condensed-header {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  ul {
    width: 100%;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    transition: opacity 200ms linear;
  }

  .mypage__gallery--flex-start {
    justify-content: flex-start;
  }

  .mypage__gallery--space-between {
    justify-content: space-between;
  }

  .mypage__gallery--centered {
    justify-content: center;
  }

  .mypage__gallery--fade-out {
    opacity: 0.5;
    justify-content: center;
  }

  // Gallery Images
  .gallery__img {
    @include sbf-body('mini-sans');

    background: white;
    display: inline-block;
    flex: 0 0 auto;
    float: left;
    height: 160px;
    width: 160px;
    min-width: 0;
    border: 3px solid white;
    margin: 8px 8px 14px;
    padding: 3px;
    box-shadow:
      0 0 0 1px #CCC,
      0 11px 0 -5px rgba(0, 0, 0, 0.2);
    transition: width 200ms linear;

    @media (min-width: $x-large) {
      width: 164px;
      height: 164px;
    }

    &:hover {
      transform: translateY(-3px);
      box-shadow:
        0 0 0 1px #CCC,
        0 14px 0 -5px rgba(0, 0, 0, 0.2);
    }

    .img-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      max-height: 100%;
      max-width: 100%;
      width: auto;
      height: auto;
    }

    a:link,
    a:visited {
      border-bottom: none;
      font-weight: 600;
      padding: 0;
    }
  }

  // Extend the gallery__img styles to the add button
  // This should really be in the editable gallery file but we can't extend from here to there.
  .add-photo {
    @extend .gallery__img;

    box-shadow: rgba(0, 0, 0, 0.2) 0 11px 0 -5px;

    // don't transform the "add" buttons
    &:hover {
      transform: none;
      box-shadow: rgba(0, 0, 0, 0.2) 0 11px 0 -5px;
      cursor: pointer;
    }
  }

  .mypage--main & {
    .list-header {
      margin: 0 auto;
      border-bottom: 0;
      background: transparent;

      .condensed-header {
        float: none;
        margin: 26px 0;
      }
    }

    ul {
      padding: 0 24px;
    }
  }
}

// Internal gallery page
.mypage--gallery {
  .mypage__gallery {
    background: none;
    border-top: 0;
    text-align: left;

    ul {
      justify-content: center;

      @media (min-width: $medium) {
        justify-content: flex-start;
      }
    }
  }

  // editable button
  .add-photo {
    p {
      font-family: $sansSerifFamily;
      text-align: center;
    }
  }
}

@media (max-width: $medium - 1) {
  .mypage__gallery .list-header .condensed-header {
    margin: 30px 0 6px 0;
  }

  .mypage--gallery .mypage__gallery ul {
    width: auto;
    margin: 38px 46px 0 46px;
  }
}

.gallery-view-more {
  @include sbf-body('mini-sans');

  font-weight: 600;
  border: none;
  text-decoration: underline;
}
